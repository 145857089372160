export const adminTypes = [
  "Admin",
  "Leidinggevende",
  "Mail / rooster",
  "Admin lonen",
];

export const userTypes = {
  ADMIN: "admin",
  STAFF: "staff",
};

export const weekDays = {
  A: {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
  },
  B: {
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
  },
};

export const personnelStatus = {
  PLANNED: "successBadge",
  AVAILABLE: "warningBadge",
  UNAVAILABLE: "defaultBadge",
};

export const tagEmojis = {
  SATURDAY_HERO: 1,
  GOOD_AVAILABILITY: 2,
  TOP_AVAILABILITY: 3,
};

export const jobFilterTypes = {
  KITCHEN: 1,
  NON_KITCHEN: 2,
  DISHES: 3,
};
